@import 'variables.scss';

.primary-fore-color {
    color: $primary-color;
}

.gradient-bg-1 {
    background: rgb(237,237,237);
    background: linear-gradient(-47deg, rgba(237,237,237,1) 0%, rgba(237,249,255,1) 19%, rgba(216,216,255,1) 47%, rgba(176,221,255,1) 70%, rgba(234,251,255,1) 100%);
    box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
    background-size: 400% 400%;
    animation: GradientBackground 10s ease infinite;
}

.gradient-bg-2 {
    background: rgb(237,237,237);
    background: linear-gradient(84deg, rgb(237, 237, 237) 0%, rgba(237,249,255,1) 19%, rgba(254,254,254,1) 73%, rgba(234,251,255,1) 100%);
    box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
}

.gradient-bg-3 {
    background: rgb(139,254,179);
    background: linear-gradient(0deg, rgba(139,254,179,1) 0%, rgba(145,177,255,1) 100%);
    box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
    background-size: 400% 400%;
    animation: GradientBackground 10s ease infinite;
}

.gradient-bg-4 {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,251,255,1) 100%);
    box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
    background-size: 400% 400%;
    animation: GradientBackground 10s ease infinite;
}

.gradient-bg-5 {
    background: rgb(255, 255, 255);
    background: linear-gradient(100deg, rgb(255, 255, 255) 0%, rgb(197, 204, 246) 19%, rgb(234, 223, 223) 73%, rgb(255, 255, 255) 100%);
    box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
    background-size: 400% 400%;
    animation: GradientBackground 5s ease infinite;
}

.ant-btn-primary {
    // background: rgb(8, 154, 199) !important;
    color: #ffffff !important;
}

@keyframes GradientBackground {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
};
