@tailwind base;
@tailwind components;
@tailwind utilities;

@import './theme.scss';
@import './animations.scss';


@font-face {
  font-family: clcFontStyle;
  src: url(../fonts/ScriptMTBold.ttf);
  /*font-weight: normal;*/
}

.font-clc {
  font-family: clcFontStyle;
}

@font-face {
  font-family: 'rosellinda';
  src: url(../fonts/Rosellinda.otf);
  /*font-weight: normal;*/
}

.font-rosellinda {
  font-family: 'rosellinda';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button, [type='button'], [type='reset'], [type='submit'] {
  // background-color: initial;
  color: initial;
}